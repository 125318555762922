<template>
  <div class="advertismentWrapper offerManagement">
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="isSpinner"/>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="filterRow offerMgntRow">
            <b-col md="12" sm="12" class="mb-1">
               <h3 class="card-title">Filters</h3>
            </b-col>
            <b-col md="3" sm="6" class="dateInput">
              <b-form-group label="Date" class="mb-0">
                <b-form-datepicker v-model="date" placeholder="Select Date" />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" class="sortInput dateInput">
              <b-form-group label="Sort By" class="mb-0">
                <b-form-select
                  :options="sortingArray"
                  v-model="sortBy"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" style="margin-top: 37px">
              <span
                @click="(date = ''), (sortBy = 'Newest')"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  color: #17D1C6;
                  font-weight: 400;
                  margin-top: 10px;
                  line-height:21px;
                "
              >
                Clear Filter</span
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" v-if="!isSpinner">
        <b-card>
           <b-row class="tableRowTitle">
            <b-col md="9" sm="7" class="my-1">
                <h3 class="card-title">Application List</h3>
            </b-col>
            <b-col md="3" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>
            <b-col class="offerListtableData" cols="12" v-if="filteredItems && filteredItems.length > 0">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(date)="data">
                  {{ data.item.date }}
                </template>

                <template #cell(Photo)="data">
                  <img src="@/assets/images/svg/document.svg" alt="pof_file" />
                  <span
                    style="
                      cursor: pointer;
                      text-decoration: underline;
                      color: #17D1C6;
                      font-weight: 500;
                      margin-left: 5px;
                    "
                    @click.prevent="
                      downloadFile(data.item.pof, data.item.pofName)
                    "
                    >View</span
                  >
                </template>

                <template #cell(property_name)="data">
                  <div v-if="data.item.property" @click="callPropertyEmit(data.item.property)">
                    <router-link target="_blank" :to="`listing-management/${data.item.property.id}`">
                    <div style="display: flex; align-items: center">
                    <span
                      style="
                        cursor: pointer;
                        text-decoration: none;
                        color: #17D1C6;
                        font-weight: 500;
                        cursor:pointer;
                      "
                      >{{ data.item.property.name }}</span
                    >
                    </div>
                    </router-link>
                  </div>
                  <div style="white-space: nowrap" v-else>N/A</div>
                </template>

                <template #cell(buyer_name)="data">
                  <div style="white-space: nowrap">
                    {{ data.item.fullName }}
                  </div>
                </template>

                <template #cell(seller_name)="data">
                  <div style="white-space: nowrap">
                    {{ data.item.sellerName }}
                  </div>
                </template>

                 <template #cell(Terms)="data">
                  <div style="white-space: nowrap" v-if="data.item.terms && data.item.terms.length <=15" >
                    {{data.item.terms}}
                  </div>
                  <div style="white-space: nowrap"  v-else-if="data.item.terms && data.item.terms.length >15">
                    <div :title="data.item.terms">
                    {{ data.item.terms ? data.item.terms.slice(0, 15)+'...' : 'N/A' }}
                    </div>
                  </div>
                  <div style="white-space: nowrap" v-else>
                    N/A
                  </div>
                </template>

                <template #cell(offerPrice)="data">
                  <div style="white-space: nowrap">
                    {{ data.item.moveInDate }}
                  </div>
                </template>

                <template #cell(status)="data">
                  <div v-if="data.item.status !== ''">
                    {{
                      offerStatusGetterArray[data.item.status - 1].displayName
                    }}
                  </div>
                  <div v-else>N/A</div>
                </template>
                <template #cell(helperEmail)="data">
                  <div v-if="data.item.helperEmailId !== ''">
                    {{
                      data.item.helperEmailId
                    }}
                  </div>
                  <div v-else>N/A</div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
            <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length">
              <!-- page length -->
              <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ totalRows }} </span> <span class="entiresText">entries</span>
              </div>


              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BDropdownDivider,
  BFormDatepicker,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapActions,mapGetters } from "vuex";
import {
  getCommaSeperatedNumber,
  downloadDocument,
} from "@/utils/commonMethods";
import moment from "moment";

export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BDropdownDivider,
    BFormDatepicker,
    CardStatisticProfitChart,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isSpinner: true,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "Newest",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "date",
          label: "Date",
         
        },
        {
          key: "property_name",
          label: "Property Name",
         
        },
        {
          key: "buyer_name",
          label: "Buyer Name",
      
        },
        {
          key: "seller_name",
          label: "Seller Name",
         
        },
        {
          key: "emailId",
          label: "Email ID",
       
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
          
        },
        {
          key: "offerPrice",
          label: "Move-in Date",
        
        },
        {
          key: "helperEmail",
          label: "Helpers Email", 
        },
        "Photo",
        {
          key: "Terms",
          label: "Funds / Notes from Applicant",
        },
        // {
        //   key: "status",
        //   label: "Status",
      
        // },
        // "action"
      ],
      items: [],
      users: [],
      properties: [],

      date: "",
      statusOptions: [],
      sortingArray: ["Newest", "Oldest"],
    };
  },

  created() {
    var self = this;
    self.userData().then(() => {
      self.allOfferData().then(() => {
          self.isSpinner = false;
      })
    });
    // try {
    //   getCollectionData(`${dbCollections.OFFERS}`)
    //     .then((querySnapshot) => {
    //       self.items = [];
    //       if (querySnapshot.empty) {
    //         self.isSpinner = false;
    //         return;
    //       }

    //       self.totalRows = self.items.length;

    //       loopFunction(querySnapshot.docs, (doc) => {
    //         return new Promise((resolve, reject) => {
    //           try {
    //             let promises = [];
    //             promises.push(
    //               new Promise((resolve2, reject2) => {
    //                 if (
    //                   !self.users.filter((x) => x.id === doc.data().userId)
    //                     .length
    //                 ) {
    //                   getCollectionData(
    //                     `${dbCollections.USERS}>${doc.data().userId}`
    //                   )
    //                     .then((userDod) => {
    //                       self.users.push(userDod.data());
    //                       resolve2({ status: true, userData: userDod.data() });
    //                     })
    //                     .catch((error) => {
    //                       resolve2({ status: false, error: error });
    //                     });
    //                 } else {
    //                   resolve2({
    //                     status: false,
    //                     error: new Error("User already exists"),
    //                   });
    //                 }
    //               })
    //             );

    //             promises.push(
    //               new Promise((resolve2, reject2) => {
    //                 if (
    //                   !self.properties.filter(
    //                     (x) => x.id === doc.data().propertyId
    //                   ).length
    //                 ) {
    //                   getCollectionData(
    //                     `${dbCollections.PROPERTY}>${doc.data().propertyId}`
    //                   )
    //                     .then((propertyDoc) => {
    //                       if (
    //                         propertyDoc.data().listingType ===
    //                         "free_or_public"
    //                       ) {
    //                         self.properties.push({
    //                           id: propertyDoc.id,
    //                           name: `${
    //                             propertyDoc.data().contractBStreetNumber
    //                               ? propertyDoc.data().contractBStreetNumber
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().contractBStreetName
    //                               ? propertyDoc.data().contractBStreetName + ","
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().contractBUnitorLot
    //                               ? propertyDoc.data().contractBUnitorLot + ", "
    //                               : ""
    //                           }${propertyDoc.data().contractBCity}, ${
    //                             propertyDoc.data().contractBState
    //                           }, ${propertyDoc.data().contractBCountry}, ${
    //                             propertyDoc.data().contractBZipCode
    //                           }`,
    //                         });
    //                       } else if (
    //                         propertyDoc.data().listingType ===
    //                         "half_way_or_shared"
    //                       ) {
    //                         self.properties.push({
    //                           id: propertyDoc.id,
    //                           name: `${
    //                             propertyDoc.data().leaseAStreetNumber
    //                               ? propertyDoc.data().leaseAStreetNumber
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().leaseAStreetName
    //                               ? propertyDoc.data().leaseAStreetName + ","
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().leaseAUnitorLot
    //                               ? propertyDoc.data().leaseAUnitorLot + ","
    //                               : ""
    //                           } ${propertyDoc.data().leaseBCity}, ${
    //                             propertyDoc.data().leaseBState
    //                           }, ${propertyDoc.data().leaseBCountry}, ${
    //                             propertyDoc.data().leaseBZipCode
    //                           }`,
    //                         });
    //                       } else if (
    //                         propertyDoc.data().listingType === "mental_or_detox"
    //                       ) {
    //                         self.properties.push({
    //                           id: propertyDoc.id,
    //                           name: `${
    //                             propertyDoc.data().ownerBStreetNumber
    //                               ? propertyDoc.data().ownerBStreetNumber
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().ownerBStreetName
    //                               ? propertyDoc.data().ownerBStreetName + ","
    //                               : ""
    //                           } ${
    //                             propertyDoc.data().ownerBUnitorLot
    //                               ? propertyDoc.data().ownerBUnitorLot + ", "
    //                               : "" 
    //                           }${propertyDoc.data().ownerBCity}, ${
    //                             propertyDoc.data().ownerBState
    //                           }, ${propertyDoc.data().ownerBCountry}, ${
    //                             propertyDoc.data().ownerBZipCode
    //                           }`, 
    //                         });
    //                       }
    //                       resolve2({
    //                         status: true,
    //                         userData: propertyDoc.data(),
    //                       });
    //                     })
    //                     .catch((error) => {
    //                       resolve2({ status: false, error: error });
    //                     });
    //                 } else {
    //                   resolve2({
    //                     status: false,
    //                     error: new Error("Property already exists"),
    //                   });
    //                 }
    //               })
    //             );

    //             Promise.all(promises)
    //               .then(() => {
    //                 resolve({ stats: true });
    //               })
    //               .catch((error) => {
    //                 resolve({ status: false, error: error });
    //               });
    //           } catch (error) {
    //             resolve({ status: false, error: error });
    //           }
    //         });
    //       })
    //         .then(() => {
    //           querySnapshot.forEach((doc) => {
    //             let userId = doc.data().userId;
    //             self.items.push({
    //               ...doc.data(),
    //               date: self.convertedDate(
    //                 doc.data().createdAt.seconds * 1000,
    //                 "MM/DD/YYYY"
    //               ),
    //               sellerName: self.users.length
    //                 ? `${
    //                     self.users.filter((x) => x.id == userId)[0].firstName
    //                   } ${self.users.filter((x) => x.id == userId)[0].lastName}`
    //                 : "N/A",
    //               property: self.properties.length
    //                 ? self.properties.filter(
    //                     (x) => x.id == doc.data().propertyId
    //                   )[0]
    //                 : { name: "N/A", id: null },
    //             });
    //           });
    //           self.isSpinner = false;
    //         })
    //         .catch((error) => {
    //           self.isSpinner = false;
    //           console.error("ERROR in loopFunction: ", error);
    //         });
    //     })
    //     .catch((error) => {
    //       self.isSpinner = false;
    //       console.error("ERROR in get property data: ", error);
    //     });
    // } catch (error) {
    //   self.isSpinner = false;
    //   console.error("ERROR in get collection data: ", error);
    // }
  },

  methods: {
    ...mapActions({
      allOfferData : "offer/allOfferData",
      userData:"user/userData"
    }),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    convertedDate(date, format) {
      return moment(date).format(format);
    },

    getCommaNum(num) {
      return getCommaSeperatedNumber(num);
    },

    downloadFile(url, name) {
      downloadDocument(url, name);
    },
    compareObjects(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 > obj2) {
        return -1;
      }
      if (obj1 < obj2) {
        return 1;
      }
      return 0;
    },
    compareObjectsDecrement(object1, object2, key) {
      const obj1 = object1[key];
      const obj2 = object2[key];
      if (obj1 < obj2) {
        return -1;
      }
      if (obj1 > obj2) {
        return 1;
      }
      return 0;
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.name}`);
    },
    // setNameOfProperty(id){
    //   var self  = this;
    //   let propertyObj = {}
    //   return new Promise((resolve,reject) => {
    //     if(self.getAllPropertyArray && self.getAllPropertyArray.length > 0){
    //       let index  = self.getAllPropertyArray.findIndex(item => {
    //         return item.id === id
    //       })
    //       if(index != -1){
    //         if (self.getAllPropertyArray[index].listingType === "free_or_public" ) {
    //           propertyObj = {
    //             id: self.getAllPropertyArray[index].id,              
    //             name: `${
    //               self.getAllPropertyArray[index].contractBStreetNumber
    //                 ? self.getAllPropertyArray[index].contractBStreetNumber
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].contractBStreetName
    //                 ? self.getAllPropertyArray[index].contractBStreetName + ","
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].contractBUnitorLot
    //                 ? self.getAllPropertyArray[index].contractBUnitorLot + ", "
    //                 : ""
    //             }${self.getAllPropertyArray[index].contractBCity}, ${
    //               self.getAllPropertyArray[index].contractBState
    //             }, ${self.getAllPropertyArray[index].contractBCountry}, ${
    //               self.getAllPropertyArray[index].contractBZipCode
    //             }`
    //           }
    //         }else if (self.getAllPropertyArray[index].listingType === "half_way_or_shared") {
    //           propertyObj = {
    //             id: self.getAllPropertyArray[index].id,
    //             name: `${
    //               self.getAllPropertyArray[index].leaseAStreetNumber
    //                 ? self.getAllPropertyArray[index].leaseAStreetNumber
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].leaseAStreetName
    //                 ? self.getAllPropertyArray[index].leaseAStreetName + ","
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].leaseAUnitorLot
    //                 ? self.getAllPropertyArray[index].leaseAUnitorLot + ","
    //                 : ""
    //             } ${self.getAllPropertyArray[index].leaseBCity}, ${
    //               self.getAllPropertyArray[index].leaseBState
    //             }, ${self.getAllPropertyArray[index].leaseBCountry}, ${
    //               self.getAllPropertyArray[index].leaseBZipCode
    //             }`,
    //           }
    //         }else if (self.getAllPropertyArray[index].listingType === "mental_or_detox") {
    //           propertyObj = {
    //             id: self.getAllPropertyArray[index].id,
    //             name: `${
    //               self.getAllPropertyArray[index].ownerBStreetNumber
    //                 ? self.getAllPropertyArray[index].ownerBStreetNumber
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].ownerBStreetName
    //                 ? self.getAllPropertyArray[index].ownerBStreetName + ","
    //                 : ""
    //             } ${
    //               self.getAllPropertyArray[index].ownerBUnitorLot
    //                 ? self.getAllPropertyArray[index].ownerBUnitorLot + ", "
    //                 : "" 
    //             }${self.getAllPropertyArray[index].ownerBCity}, ${
    //               self.getAllPropertyArray[index].ownerBState
    //             }, ${self.getAllPropertyArray[index].ownerBCountry}, ${
    //               self.getAllPropertyArray[index].ownerBZipCode
    //             }`, 
    //           }
    //         }
    //         resolve({ name: propertyObj.name, id: propertyObj.id })
    //       }else{
    //         resolve({ name: "N/A", id: null })
    //       }
    //     }else{
    //       resolve({ name: "N/A", id: null })
    //     }
    //   })
    // }
  },

  computed: {
    ...mapGetters({
      getStatuses: "admin/offerStatusGetter",
      offerStatusGetterArray: "admin/offerStatusGetter",
      getAllOfferArray:"offer/getAllOffer",
      allUserDataArray:"user/allUserData"
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    filteredItems() {
      var self = this;
      let obj = {};
      let userName = '';
      self.items = [];
      // console.log('getAllOfferArray',self.getAllOfferArray);
      try{
        if(self.getAllOfferArray && self.getAllOfferArray.length > 0 && self.allUserDataArray && self.allUserDataArray.length){
        self.isSpinner = false;
          self.getAllOfferArray.forEach(data => {
              if(data.userId){
                let index = self.allUserDataArray.findIndex(item => {
                  return item.id == data.userId
                })
                if(index != -1){
                  userName = `${self.allUserDataArray[index].firstName} ${self.allUserDataArray[index].lastName}`
                }else{
                  userName = 'N/A'
                }
              }else{
                userName = 'N/A'
              }
              obj = {
                ...data,
                date: self.convertedDate(
                  data.createdAt.seconds * 1000,
                  "MM/DD/YYYY"
                ),
                moveInDate: self.convertedDate(
                  data.offerPrice.seconds * 1000,
                  "MM/DD/YYYY"
                ),
                sellerName: userName,
                property: {name:data.propertyAddress,id:data.propertyId}
              }
              self.items.push(obj);
             
          })
          self.isSpinner = false;
          let tmp = self.items  ;
          if (self.date) {
            tmp = tmp.filter(
              (f) =>
                f.date === self.convertedDate(new Date(self.date), "MM/DD/YYYY")
            );
          }
          if (self.sortBy == "Newest") {
            tmp.sort((book1, book2) => {
              return self.compareObjects(book1, book2, "createdAt");
            });
          } else if (self.sortBy == "Oldest") {
            tmp.sort((book1, book2) => {
              return self.compareObjectsDecrement(book1, book2, "createdAt");
            });
          }
          self.totalRows = tmp.length;
          self.isSpinner = false;
          return tmp;
        }
      }catch(error){
        self.isSpinner = false;
        console.error("ERROR in get collection data: ", error);
      }
    },
    filteredStatuses() {
      var self = this;
      if (self.getStatuses && self.getStatuses.length) {
        self.statusOptions = [
          { text: "All", value: null },
          ...self.getStatuses.map((x) => {
            return { text: x.displayName, value: x.key };
          }),
        ];
      }
      return self.getStatuses.map((x) => {
        return {
          label: x.displayName,
          key: x.key,
        };
      });
    },
  },
};
</script>

<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>